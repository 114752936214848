import Rest from '@/services/Rest';

/**
 * @typedef {ClientUpdateService}
 */
export default class ClientUpdateService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/client-update'
}
