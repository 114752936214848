<template>
  <div>
    <b-overlay
      :show="loadingEmailUpdate"
      rounded
      opacity="0.6"
      class="d-inline-block col-12">
    <b-row class="mb-3">
      <b-col>
        <h5 class="titulo-item">{{ $t('shared.text_2066') }}</h5>

        <b-col cols="12">
          <div class="product-main">
            <div class="img-product">
              <img
                v-if="
                  !loading &&
                  sale &&
                  sale.product &&
                  sale.product.images &&
                  sale.product.images.length
                "
                :src="sale.product.images[0].path"
              />
            </div>
            <div class="name-product" v-if="!loading && sale.product">
              <span>{{ $t('shared.text_2023') }} #{{ sale.product.id }}</span>
              <template v-if="!sale.proposal">
                <p v-if="sale.product && sale.product.name">
                  {{ sale.product.name }}
                </p>
                <p v-else>{{ sale.invoices[0].product.name }}</p>
                <p class="hash" v-if="sale.offer">
                  {{ $t('shared.text_2067') }} {{ sale.offer.hash }}
                </p>
                <p
                  class="hash"
                  v-if="
                    sale.invoices &&
                    sale.invoices[0] &&
                    sale.invoices[0].offer.hash
                  "
                >
                  {{ $t('shared.text_2067') }} {{ sale.invoices[0].offer.hash }}
                </p>
              </template>
              <template v-else>
                <p v-if="sale.proposal && sale.proposal.name">
                  {{ sale.proposal.name }}
                </p>
                <p class="hash" v-if="sale.offer">
                  {{ $t('shared.text_2067') }} {{ sale.offer.hash }}
                </p>
              </template>
            </div>
          </div>
        </b-col>
      </b-col>
    </b-row>

    <b-row v-if="openType !== 'lead'">
      <b-col class="sumary">
        <b-row class="mb-3" v-if="openType !== 'sale'">
          <!-- Sale ID -->
          <b-col cols="6">
            <p class="label-campo">{{ $t('shared.text_1996') }}</p>
            <p class="dados-campo">{{ sale.id }}</p>
          </b-col>
          <!-- Status -->
          <b-col cols="6">
            <p class="label-campo">{{ $t('shared.text_1997') }}</p>
            <template>
              <p class="dados-campo" :class="getStatus(sale.status).class">
                {{ getStatus(sale.status).text }}
              </p>
            </template>
          </b-col>
        </b-row>
        <b-row class="mb-3" v-else>
          <!-- Statement ID -->
          <b-col cols="6">
            <p class="label-campo">{{ $t('shared.text_2152') }}</p>
            <p class="dados-campo">{{ statement_id }}</p>
          </b-col>
          <!-- Sale ID -->
          <b-col cols="6">
            <p class="label-campo">{{ $t('shared.text_1996') }}</p>
            <p class="dados-campo">{{ sale.id }}</p>
          </b-col>
        </b-row>

        <b-row class="mb-3" v-if="openType !== 'sale'">
          <!-- Payment at -->
          <b-col
            v-if="showPaidAt(sale.status) || showRefundedAt(sale.status) || showChargedbackAt(sale.status)"
            cols="6"
          >
            <p class="label-campo">{{ $t('shared.text_2051') }}</p>
            <p class="dados-campo" v-if="typeof sale.paid_at !== 'undefined' && sale.paid_at">{{ sale.paid_at | date }}</p>
            <p class="dados-campo" v-else>--</p>
          </b-col>
          <!-- status = paid || trialing || refund_pending: Available at -->
          <b-col
            v-if="showPaidAt(sale.status)"
            cols="6"
          >
            <p class="label-campo">{{ $t('shared.text_2436') }}</p>
            <p class="dados-campo">{{ sale.available_date_sale+'T00:00:00' | date }}</p>
          </b-col>
          <!-- status = refunded: Refunded at -->
          <b-col
            v-else-if="showRefundedAt(sale.status)"
            cols="6"
          >
            <p class="label-campo">{{ $t('shared.refunded_at') }}</p>
            <p class="dados-campo">{{ sale.refunded_at | date }}</p>
          </b-col>
          
          <!-- status = chargedback: Chargedback at -->
          <b-col
            v-else-if="showChargedbackAt(sale.status)"
            cols="6"
          >
            <p class="label-campo">{{ $t('shared.chargedback_at') }}</p>
            <p class="dados-campo">{{ sale.refunded_at | date }}</p>
          </b-col>
        </b-row>

        <b-row class="mb-3" v-else>
          <!-- purchase -->
          <b-col cols="6">
            <p class="label-campo">{{ $t('views.affiliation.text_1523') }}</p>
            <p class="dados-campo">{{ statement_created_at | date }}</p>
          </b-col>
          <!-- Status -->
          <b-col cols="6">
            <p class="label-campo">{{ $t('shared.text_1997') }}</p>
            <template>
              <p class="dados-campo" :class="getStatus(sale.status).class">
                {{ getStatus(sale.status).text }}
              </p>
            </template>
          </b-col>
        </b-row>

        <b-row class="mb-3" v-if="openType == 'sale'">
          <!-- Payment at -->
          <b-col
            v-if="showPaidAt(sale.status) || showRefundedAt(sale.status) || showChargedbackAt(sale.status)"
            cols="6"
          >
            <p class="label-campo">{{ $t('shared.text_2051') }}</p>
            <p class="dados-campo" v-if="typeof sale.paid_at !== 'undefined' && sale.paid_at">{{ sale.paid_at | date }}</p>
            <p class="dados-campo" v-else>--</p>
          </b-col>

          <!-- status = paid || trialing || refund_pending: Available at -->
          <b-col
            v-if="showPaidAt(sale.status)"
            cols="6"
          >
            <p class="label-campo">{{ $t('shared.text_2153') }}</p>
            <p class="dados-campo">{{ statement_available_date+"T00:00:00" | date }}</p>
          </b-col>
          <!-- status = refunded: Refunded at -->
          <b-col
            v-else-if="showRefundedAt(sale.status)"
            cols="6"
          >
            <p class="label-campo">{{ $t('shared.refunded_at') }}</p>            
            <p class="dados-campo">{{ sale.refunded_at | date }}</p>
          </b-col>
          <!-- status = chargedback: Chargedback at -->
          <b-col
            v-else-if="showChargedbackAt(sale.status)"
            cols="6"
          >
            <p class="label-campo">{{ $t('shared.chargedback_at') }}</p>
            <p class="dados-campo">{{ sale.refunded_at | date }}</p>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <!-- Product Type -->
          <b-col cols="6">
            <p class="label-campo">{{ $t('shared.text_2168') }}</p>
            <p class="dados-campo">
              {{
                sale.product.type == 'SUBSCRIPTION'
                  ? $t('shared.text_2025')
                  : $t('shared.text_2026')
              }}
            </p>
          </b-col>
          <!-- Contract -->
          <b-col cols="6">
            <p class="label-campo">{{ $t('shared.text_2053') }}</p>
            <!-- Bound Contract -->
            <button
              v-if="sale.type === 'SUBSCRIPTION' && id_contract"
              type="button"
              class="btn btn-link"
              id="contrato-vinculado"
              variant="secondary_outline"
              @click="openContract(id_contract)"
            >
              <div class="align-items-center d-flex">
                <span
                  class="link-item pr-1 dados-campo"
                  style="color: #ff8200; text-decoration: underline"
                >
                  #{{ id_contract }}
                </span>
                <span class="link-item dados-campo" style="color: #ff8200">
                  <img src="@/assets/img/icons/link-externo3.svg" />
                </span>
              </div>
            </button>
            <b-tooltip
              v-if="sale.type === 'SUBSCRIPTION'"
              target="contrato-vinculado"
              :title="$t('shared.text_2054')"
            />
            <p v-if="sale.type !== 'SUBSCRIPTION'" class="dados-campo">--</p>
          </b-col>
        </b-row>

        <!-- Reason for Refusal -->
        <b-row v-if="sale.status == 'refused'" class="mb-3">
          <b-col cols="12">
            <p class="label-campo">{{ $t('shared.text_2055') }}</p>
            <p
              class="dados-campo"
              :class="getStatus(sale.status).class"
              v-if="sale.error_code_reason"
            >
              {{ checkError(sale.error_code_reason) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Period -->
    <b-row
      class="mb-3"
      v-if="openType !== 'lead' && sale.contractPeriod !== null"
    >
      <b-col cols="6">
        <p class="label-campo">{{ $t('shared.text_2162') }}</p>
        <p class="dados-campo">
          {{
            sale.contractPeriod && typeof sale.contractPeriod.period !== 'undefined'
              ? getContractPeriod(sale.contractPeriod.period)
              : '01'
          }}
        </p>
      </b-col>
      <b-col cols="6">
        <p class="label-campo">{{ $t('shared.text_2163') }}</p>
        <p class="dados-campo">
          {{ sale.contractPeriod && formatCurrentCharge(sale.contractPeriod.current_charge) }}
          de
          {{
            sale.contractPeriod && sale.contractPeriod.total_charges > 0
              ? formatCurrentCharge(sale.contractPeriod.total_charges)
              : 'indeterminado'
          }}
        </p>
      </b-col>
    </b-row>

    <!-- Nota Fiscal -->
    <b-row class="item-dados pt-0" v-if="openType != 'lead'">
      <b-col cols="6">
        <p class="label-campo">{{ $t('shared.text_2088') }}</p>
        <p class="dados-campo">
          <span class="nota-status emitida" v-if="sale.note_issue_by"
            ><img src="@/assets/img/icons/mini-check.svg" />
            {{ $t('shared.text_2090') }}
          </span>
          <span class="nota-status emitida" v-if="!sale.note_issue_by"
            ><img src="@/assets/img/icons/mini-x.svg" />
            {{ $t('shared.text_2091') }}
          </span>
        </p>
      </b-col>
      <b-col cols="6">
        <p class="label-campo">{{ $t('shared.text_2092') }}</p>
        <!-- Note -->
        <button
          v-if="
            !sale.note_issue_by &&
            sale.fiscal_center_id &&
            sale.seller_id == sale.fiscal_center_user_id &&
            ((sale.country_code && sale.country_code == 'BR') ||
              !sale.country_code)
          "
          type="button"
          class="btn btn-link"
          variant="secondary_outline"
          @click="openNote(sale.fiscal_center_id)"
        >
          <div class="align-items-center d-flex">
            <span
              class="link-item pr-1 dados-campo"
              style="color: #ff8200; text-decoration: underline"
            >
              {{ $t('shared.text_2139') }}
            </span>
            <span class="link-item dados-campo" style="color: #ff8200">
              <img src="@/assets/img/icons/link-externo3.svg" />
            </span>
          </div>
        </button>
        <p
          v-else-if="
            sale.note_issue_by &&
            sale.fiscal_center_id &&
            sale.seller_id == sale.fiscal_center_user_id &&
            ((sale.country_code && sale.country_code == 'BR') ||
              !sale.country_code)
          "
          class="dados-campo"
        >
          <span>{{ sale.note_issue_by }}</span>
        </p>
        <p
          v-else-if="
            !sale.note_issue_by &&
            sale.fiscal_center_id &&
            sale.seller_id != sale.fiscal_center_user_id &&
            ((sale.country_code && sale.country_code == 'BR') ||
              !sale.country_code)
          "
          class="dados-campo"
        >
          <span>{{ $t('shared.text_2140') }}</span>
        </p>
        <p
          v-else-if="
            !sale.note_issue_by &&
            !sale.fiscal_center_id &&
            ((sale.country_code && sale.country_code == 'BR') ||
              !sale.country_code)
          "
          class="dados-campo"
        >
          <span>{{ $t('shared.text_2140') }}</span>
        </p>
        <p
          v-else-if="
            !sale.note_issue_by &&
            !sale.fiscal_center_id &&
            sale.country_code &&
            sale.country_code != 'BR'
          "
          class="dados-campo"
        >
          <span id="info-nf" class="nota-status emitida">
            {{ $t('shared.text_2141') }}
            <img src="@/assets/img/icons/info.svg" />
          </span>
          <b-tooltip target="info-nf" :title="$t('shared.text_2142')" />
        </p>
      </b-col>
    </b-row>

    <b-row class="mb-3" v-if="openType == 'sale'">
      <b-col v-if="statement_type != 'SALE'" cols="6">
        <p class="label-campo">{{ $t('shared.text_2154') }}</p>
        <p class="dados-campo">{{ statement_type }}</p>
      </b-col>

      <b-col
        v-if="statement_type == 'WITHDRAW' || statement_type == 'WITHDRAW_FEE'"
        cols="6"
      >
        <p class="label-campo">ID do Saque</p>
        <p class="dados-campo">{{ statement_withdraw_id }}</p>
      </b-col>
    </b-row>

    <!-- Avaliação -->
    <div class="item-dados" v-if="sale.product_rating">
      <b-col>
        <h5 class="titulo-item">{{ $t('shared.text_2068') }}</h5>

        <div class="avaliacao-item">
          <b-form-rating
            id="rating"
            v-model="sale.product_rating"
            variant="warning"
            class="mb-2"
            readonly
            show-value
            show-value-max
            size="lg"
          ></b-form-rating>
        </div>
        <b-col
          class="product-avaliacao text-avaliacao"
          v-if="sale.avaliation.length"
        >
          <img src="@/assets/img/icons/info-prod.svg" />
          {{ sale.avaliation[0].avaliation_value }}
        </b-col>
      </b-col>
    </div>

    <!-- Client -->
    <b-row class="item-dados mb-n4" v-if="sale.client">
      <b-col cols="12" class="mb-3">
        <h5 class="titulo-item" v-if="sale.client && sale.client.name">
          {{ $t('shared.text_2075') }}
        </h5>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="label-campo" v-if="sale.client.name">
          {{ $t('shared.text_2073') }}
        </p>
        <p class="dados-campo" v-if="sale.client.name">
          <span>{{ sale.client.name }}</span>
        </p>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="label-campo" v-if="sale.client.email">
          {{ $t('shared.text_2074') }}
        </p>
        <p class="dados-campo" v-if="sale.client.email">

          <div v-if="isEditing">
            <input v-model="editedEmail" class="email-input"/>

            <font-awesome-icon
              :icon="['fas', 'check']"
              @click="saveEmail"
              class="email-edit-icon" />
            <font-awesome-icon
              :icon="['fas', 'times']"
              @click="cancelEdit"
              class="email-edit-icon" />

            <p v-if="emailError" class="email-error-message">{{ emailError }}</p>
          </div>

          <div v-else>
            <span>{{ displayEmail }}</span>
            <font-awesome-icon v-if="allowEmailEdit && show_edit_email"
              :icon="['fas', 'pen']"
              @click="startEdit"
              class="email-edit-icon" />
          </div>
        </p>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="label-campo" v-if="sale.client.cellphone || sale.cellphone">
          {{ $t('shared.text_2076') }}
        </p>
        <p class="dados-campo" v-if="sale.client.cellphone || sale.cellphone">
          <span
            v-if="
              /\#/.test(sale.client ? sale.client.cellphone : sale.cellphone)
            "
          >
            {{ sale.client ? sale.client.cellphone : sale.cellphone }}
          </span>

          <a
            v-else
            :href="
              formatTel(sale.client ? sale.client.cellphone : sale.cellphone)
            "
            target="_blank"
            class="whats-cliente"
            >{{ sale.client.cellphone }}
            <font-awesome-icon :icon="['fab', 'whatsapp']"
          /></a>
        </p>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p
          class="label-campo"
          v-if="
            sale.client.document_type &&
            (sale.client.document || sale.client.cpf)
          "
        >
          {{
            sale.client.document_type
              ? sale.client.document_type.toUpperCase()
              : 'Documento'
          }}
        </p>
        <p
          class="dados-campo"
          v-if="
            sale.client.document_type &&
            (sale.client.document || sale.client.cpf)
          "
        >
          <span>{{
            openType != 'lead' ? sale.client.document : sale.client.cpf
          }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.client && sale.client.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t('shared.text_2078') }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.client.zipcode }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.client && sale.client.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t('shared.text_2079') }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.client.street }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.client && sale.client.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t('shared.text_2080') }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.client.number }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.client && sale.client.zipcode" cols="12" class="mb-3">
        <p class="label-campo" v-if="sale.client.complement">
          {{ $t('shared.text_2081') }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.client.complement }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.client && sale.client.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t('shared.text_2082') }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.client.neighborhood }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.client && sale.client.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t('shared.text_2083') }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.client.city }}</span>
        </p>
      </b-col>
      <b-col v-if="sale.client && sale.client.zipcode" cols="12" class="mb-3">
        <p class="label-campo">
          {{ $t('shared.text_2084') }}
        </p>
        <p class="dados-campo">
          <span>{{ sale.client.uf }}</span>
        </p>
      </b-col>
    </b-row>

    <!-- Affiliate -->
    <b-row v-if="sale.affiliate" class="item-dados">
      <b-col>
        <h5 class="titulo-item">{{ $t('shared.text_2072') }}</h5>
        <p class="label-campo">
          <span class="title-item">{{ $t('shared.text_2073') }}</span>
          <span v-if="sale.affiliate.name">{{ sale.affiliate.name }}</span>
        </p>
        <p class="label-campo">
          <span class="title-item">{{ $t('shared.text_2074') }}</span>
          <span>{{ sale.affiliate.email }}</span>
        </p>
      </b-col>
    </b-row>

    <!-- Cupom -->
    <b-row class="item-dados" v-if="sale.coupon_id">
      <b-col>
        <h5 class="titulo-item">{{ $t('shared.text_2069') }}</h5>
        <p class="label-campo">
          <span class="title-item">{{ $t('shared.text_2070') }}</span>
          <span v-if="sale.coupon.name">{{ sale.coupon.name }}</span>
        </p>

        <p class="label-campo">
          <span class="title-item">{{ $t('shared.text_2071') }}</span>
          <span v-if="sale.coupon.type == 'AMOUNT'">
            <template v-if="sale.base_currency">
              {{ sale.base_currency.currency_symbol }}
            </template>
            {{ formatMoney(sale.coupon.amount) }}
          </span>
          <span v-if="sale.coupon.type == 'PERCENTAGE'">
            {{ sale.coupon.amount }}%
          </span>
        </p>
      </b-col>
    </b-row>

    <!-- Payment details -->
    <b-row class="item-dados">
      <b-row>
        <h5 class="titulo-item">{{ $t('shared.text_2132') }}</h5>
        <b-col cols="12" class="pb-3">
          <p class="dados-campo">
            <!-- National/International -->
            <b-badge
              variant="secondary"
              v-if="
                (sale.country_code && sale.country_code == 'BR') ||
                !sale.country_code
              "
            >
              <font-awesome-icon
                :icon="getIconMethod(sale.method, sale.base_currency_id)"
                class="icon-blue"
                :id="`method-icon-${sale.id}`"
              />
              {{ $t('shared.text_2061') }}
            </b-badge>
            <b-badge
              variant="secondary"
              v-if="sale.country_code && sale.country_code != 'BR'"
            >
              <div class="float-left mr-1">
                <img src="@/assets/img/icons/international-blue.svg" />
              </div>
              {{ $t('shared.text_2062') }}
            </b-badge>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <!-- Fees -->
        <b-col
          v-if="
            sale.product &&
            sale.product.installments > 1 &&
            sale.product.no_interest_installments != null
          "
          cols="12"
          class="mt-2"
        >
          <p class="label-campo">{{ $t('shared.text_2437') }}</p>
          <p class="dados-campo mt-2">
            <b-badge variant="primary"
              >{{
                sale.product.no_interest_installments
                  ? $t('shared.text_2063')
                  : $t('shared.text_2064')
              }}{{ $t('shared.text_2065') }}</b-badge
            >
          </p>
        </b-col>
      </b-row>
      <b-row v-show="interAccess">
        <!-- Local Currency -->
        <b-col v-if="sale.local_currency !== undefined" cols="12" class="mt-2">
          <p class="label-campo mr-2">{{ $t('shared.text_2143') }}</p>
          <span v-if="sale.local_currency.name">{{
            sale.local_currency.name
          }}</span>
        </b-col>
        <!-- Base Currency -->
        <b-col v-if="sale.base_currency !== undefined" cols="12" class="mt-4">
          <p class="mr-2 label-campo">{{ $t('shared.text_2144') }}</p>
          <section class="d-flex align-items-center">
            <div class="chip" :style="setColors(sale.base_currency.currency)">
              {{ sale.base_currency.currency_symbol }}
            </div>
            <span> {{ sale.base_currency.currency }}</span>
          </section>
        </b-col>
        <!-- Country -->
        <b-col v-if="sale.country !== undefined" cols="12" class="mt-4">
          <p class="mr-2 label-campo">{{ $t('shared.text_2150') }}</p>
          <section class="d-flex align-items-center">
            <img
              class="mr-2"
              style="height: 30px"
              :src="
                require(`@/assets/locales/flag-${sale.country.country_code}.svg`)
              "
            />
            <span>
              {{
                sale.country && sale.country.name ? sale.country.name : '--'
              }}</span
            >
          </section>
        </b-col>
      </b-row>
      <b-row>
        <!-- Date -->
        <b-col cols="6" class="mb-3" v-if="sale.paid_at">
          <p class="label-campo">{{ $t('shared.text_2133') }}</p>
          <p class="dados-campo">
            {{ sale.paid_at | date }}
          </p>
        </b-col>
        <!-- Time -->
        <b-col cols="6" class="mb-3" v-if="sale.paid_at">
          <p class="label-campo">{{ $t('shared.text_2134') }}</p>
          <p class="dados-campo">
            {{ getHour(sale.paid_at) }}
          </p>
        </b-col>
        <!-- Currency -->
        <b-col cols="6" class="mb-3">
          <p class="label-campo">{{ $t('shared.text_2135') }}</p>
          <p class="dados-campo">
            {{
              sale.local_currency && sale.local_currency.name
                ? sale.local_currency.name
                : '--'
            }}
          </p>
        </b-col>
        <!-- Country -->
        <b-col cols="6" class="mb-3">
          <p class="label-campo">{{ $t('shared.text_2136') }}</p>
          <p class="dados-campo">
            {{
              sale.international_country && sale.international_country.name
                ? sale.international_country.name
                : sale.country && sale.country.name
                ? sale.country.name
                : '--'
            }}
          </p>
        </b-col>
        <!-- Method -->
        <b-col cols="6" class="mb-3">
          <p class="label-campo">{{ $t('shared.text_2059') }}</p>
          <p class="dados-campo">
            {{ getMethod(sale.method) }}
          </p>
        </b-col>
        <!-- Used Card -->
        <b-col cols="6" class="mb-3">
          <template v-if="sale.method === 'CREDIT_CARD'">
            <p class="label-campo">{{ $t('shared.text_2060') }}</p>
            <p class="dados-campo" v-if="sale.card_last_digits">
              #### #### #### {{ sale.card_last_digits }}
            </p>
            <p class="dados-campo" v-else>---</p>
          </template>
        </b-col>
        <!-- Gross Value -->
        <b-col cols="6" class="mb-3">
          <p class="label-campo">{{ $t('shared.text_2056') }}</p>
          <p class="dados-campo" v-if="sale.total && sale.installments > 1">
            <template v-if="sale.target_currency_symbol">
              {{ sale.target_currency_symbol }}
            </template>
            {{ formatMoney(+sale.total) }}
            <span v-if="sale.installments > 1">
              {{ $t('shared.text_2057') }} {{ sale.installments }}x
            </span>
          </p>
          <p class="dados-campo" v-else-if="sale.amount">
            <template v-if="sale.target_currency_symbol">
              {{ sale.target_currency_symbol }}
            </template>
            {{ formatMoney(+sale.amount) }}
            <span v-if="sale.installments > 1">
              {{ $t('shared.text_2057') }} {{ sale.installments }}x
            </span>
          </p>
          <p v-else class="dados-campo">
            <template v-if="sale.base_currency">
              {{ sale.base_currency.currency_symbol }}
            </template>
            0,00
          </p>
        </b-col>
        <!-- Received Amount -->
        <b-col cols="6" class="mb-3">
          <p class="label-campo">{{ $t('shared.text_2058') }}</p>
          <p v-if="sale.balance" class="dados-campo">
            <template v-if="sale.target_currency_symbol">
              {{ sale.target_currency_symbol }}
            </template>
            {{ formatMoney(sale.balance) }}
          </p>
          <p v-else class="dados-campo">
            <template v-if="sale.target_currency_symbol">
              {{ sale.target_currency_symbol }}
            </template>
            0,00
          </p>
        </b-col>
        <!-- Participações -->
        <b-row v-if="openType != 'lead' && distribuitions.length && ((sale.status !== 'refunded' || sale.status !== 'chargedback') || ((sale.status === 'refunded' || sale.status === 'chargedback') && sale.country.country_code === 'BR'))" class="mt-3">
          <b-col cols="12" class="mb-2">
            <div class="label-campo">{{ $t('shared.text_2137') }}</div>
          </b-col>
          <template v-if="sale.local_currency && sale.local_currency.currency == 'BRL'">
            <b-col cols="8" class="mb-2">
              <div class="grid-participations">
                <span class="subtitle-item">{{ $t('shared.text_2094') }}</span>
              </div>
            </b-col>
            <b-col cols="4" class="mb-2">
              <div
                class="align-items-center d-flex grid-participations h-100 justify-content-end"
              >
                <span class="desctitle-item" style="color: #2133d2">
                  <template v-if="sale.base_currency">
                    {{ sale.base_currency.currency_symbol }}
                  </template>
                  {{ formatMoney(sale.amount) }}
                </span>
              </div>
            </b-col>
          </template>
          <template v-else-if="sale.local_currency && sale.local_currency.currency != 'BRL'">
            <b-col cols="6" class="mb-2">
              <div class="align-items-center d-flex grid-participations h-100">
                <span class="subtitle-item">{{ $t('shared.text_2138') }}</span>
              </div>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end mb-2">
              <div class="align-items-center d-flex">
                <span class="desctitle-item pr-1 dados-campo">
                  <template v-if="sale.local_currency">
                    {{ sale.local_currency.currency_symbol }}
                  </template>
                  {{ formatMoney(1) }}
                </span>
              </div>
              <div class="align-items-center d-flex pr-1">
                <span>
                  <img src="@/assets/img/icons/arrow-right.svg" />
                </span>
              </div>
              <div class="align-items-center d-flex">
                <span class="desctitle-item pr-1 dados-campo">
                  <template v-if="sale.target_currency_symbol">
                    {{ sale.target_currency_symbol }}
                  </template>
                  {{ formatMoney(sale.transaction_exchange.exchange_rate,6) }}
                </span>
              </div>
              <div class="align-items-center d-flex">
                <span id="info-exchange-payment">
                  <img src="@/assets/img/icons/info.svg" />
                </span>
              </div>
              <b-tooltip
                target="info-exchange-payment"
                :title="
                  $t('shared.text_2147') +
                  getDate(sale.transaction_exchange.created_at)
                "
              />
            </b-col>
            <b-col cols="6" class="mb-2">
              <div class="grid-participations">
                <span class="subtitle-item">
                  {{ $t('shared.text_2094') }}
                </span>
              </div>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end mb-2">
              <div class="align-items-center d-flex">
                <span class="desctitle-item pr-1 dados-campo">
                  <template v-if="sale.local_currency">
                    {{ sale.local_currency.currency_symbol }}
                  </template>
                  {{ formatMoney(sale.local_amount) }}
                </span>
              </div>
              <div class="align-items-center d-flex pr-1">
                <span>
                  <img src="@/assets/img/icons/arrow-right.svg" />
                </span>
              </div>
              <div class="align-items-center d-flex">
                <span class="desctitle-item pr-1 dados-campo">
                  <template v-if="sale.target_currency_symbol">
                    {{ sale.target_currency_symbol }}
                  </template>
                  {{ formatMoney(sale.transaction_exchange.gross_amount) }}
                </span>
              </div>
              <div class="align-items-center d-flex">
                <span id="info-value-payment">
                  <img src="@/assets/img/icons/info.svg" />
                </span>
              </div>
              <b-tooltip
                target="info-value-payment"
                :title="
                  $t('shared.text_2145') +
                  sale.local_currency && sale.local_currency.currency +
                  $t('shared.text_2146') +
                  sale.local_currency && sale.base_currency.currency +
                  '.'
                "
              />
            </b-col>
          </template>
          <template v-for="distribuition in distribuitions">
            <b-col cols="8" class="mb-2">
              <div class="grid-participations">
                <span class="subtitle-item">
                  {{
                    distribuition.type
                      ? getParticipations(distribuition.type)
                      : getParticipations(distribuition.role)
                  }}
                  {{ distribuition.type ? '' : `"${distribuition.name}"` }}
                </span>
              </div>
            </b-col>
            <b-col cols="4" class="mb-2">
              <div
                class="align-items-center d-flex grid-participations h-100 justify-content-end"
              >
                <span v-if="distribuition.participation > 0">
                  {{ distribuition.participation }}%
                </span>

                <span
                  v-if="sale.target_currency_symbol"
                  class="desctitle-item item-part"
                  style="color: rgb(255, 12, 55)"
                  >- {{ sale.target_currency_symbol }}
                  {{
                    distribuition.value
                      ? distribuition.value < 0 ? formatMoney(distribuition.value * -1) : formatMoney(distribuition.value)
                      : distribuition.balance < 0 ? formatMoney(distribuition.balance * -1) : formatMoney(distribuition.balance)
                  }}
                </span>
              </div>
            </b-col>
          </template>
          <template v-if="sale.status !== 'refunded' && sale.status !== 'chargedback'">
            <b-col cols="8">
              <div class="grid-participations">
                <span class="subtitle-item">{{ $t('shared.text_2438') }}</span>
              </div>
            </b-col>
            <b-col cols="4">
              <div
                class="align-items-center d-flex grid-participations h-100 justify-content-end"
              >
                <span class="desctitle-item" style="color: #2133d2">
                  <template v-if="sale.target_currency_symbol">
                    {{ sale.target_currency_symbol }}
                  </template>
                  {{ formatMoney(sale.balance) }}
                </span>
              </div>
            </b-col>
          </template>
          <template v-else>
            <b-col>
              <template>
                <b-col cols="12" class="mb-2">
                  <div class="grid-participations">
                    <span class="subtitle-item">{{
                      $t('shared.text_2180')
                    }}</span>
                    <span
                      class="desctitle-item"
                      style="color: #2133d2; float: right"
                    >
                      <template v-if="sale.target_currency_symbol">
                        {{ sale.target_currency_symbol }}
                      </template>
                      {{ 
                          formatMoney(sale.balance_sale)
                      }}
                    </span>
                  </div>
                </b-col>
              </template>
            </b-col>
          </template>
        </b-row>
      </b-row>
    </b-row>
    <b-row class="item-dados" v-if="(sale.status === 'refunded' || sale.status === 'chargedback')">
      <b-col class="sumary">
        <b-row>
          <b-col cols="12" class="mb-2">
            <div class="label-campo" v-if="sale.status === 'refunded'">{{ $t('shared.text_2174') }}</div>
            <div class="label-campo" v-else>{{ $t('shared.charge_details') }}</div>
          </b-col>
        </b-row>

        <b-col>
          <template
            v-if="
              sale.base_currency.currency === 'BRL' && (sale.status !== 'refunded' && sale.status !== 'chargedback')
            "
          >
            <b-col cols="12" class="mb-2">
              <div class="grid-participations">
                <span class="subtitle-item">{{ $t('shared.text_2175') }}</span>
                <span class="desctitle-item" style="float: right">
                  <template v-if="sale.base_currency">
                    {{ sale.base_currency.currency_symbol }}
                  </template>
                  {{ formatMoney(+sale.total) }}
                </span>
              </div>
            </b-col>
          </template>
        </b-col>
        <b-col>
          <template
            v-if="
              sale.base_currency.currency === 'BRL' && (sale.status !== 'refunded' && sale.status !== 'chargedback')
            "
          >
            <b-col cols="12" class="mb-2">
              <div class="grid-participations">
                <span class="subtitle-item">{{ $t('shared.text_2176') }}</span>
                <span
                  class="desctitle-item item-refunded"
                  style="color: rgb(255, 12, 55); float: right"
                >
                  <template v-if="sale.base_currency">
                    - {{ sale.target_currency_symbol }}
                  </template>
                  <span class="item-refunded item-part" v-if="sale.balance_refunded">
                    {{ sale.balance_refunded < 0 ? formatMoney(sale.balance_refunded * -1) : formatMoney(sale.balance_refunded) }}
                  </span>
                  <span class="item-refunded item-part" v-else> 0,00</span>
                </span>
              </div>
            </b-col>
          </template>
        </b-col>

        <b-row v-if="sale.status === 'refunded' || sale.status === 'chargedback'" class="mt-3">
          <template v-if="sale.local_currency.currency !== 'BRL'">
            <b-col cols="6" class="mb-2">
              <div class="align-items-center d-flex grid-participations h-100">
                <span class="subtitle-item">{{ $t('shared.text_2179') }}</span>
              </div>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end mb-2">
              <div class="align-items-center d-flex">
                <span class="desctitle-item pr-1 dados-campo">
                  <template v-if="sale.local_currency">
                    {{ sale.local_currency.currency_symbol }}
                  </template>
                  {{ formatMoney(1) }}
                </span>
              </div>
              <div class="align-items-center d-flex pr-1">
                <span>
                  <img src="@/assets/img/icons/arrow-right.svg" />
                </span>
              </div>
              <div class="align-items-center d-flex">
                <span class="desctitle-item pr-1 dados-campo">
                  <template v-if="sale.target_currency_symbol">
                    {{ sale.target_currency_symbol }}
                  </template>
                  {{ formatMoney(sale.sale_transaction_exchange_refund.exchange_rate,6) }}
                </span>
              </div>
              <div class="align-items-center d-flex">
                <span id="info-exchange-refunded">
                  <img src="@/assets/img/icons/info.svg" />
                </span>
              </div>
              <b-tooltip
                target="info-exchange-refunded"
                :title="
                  $t('shared.text_2178') +
                  getDate(sale.sale_transaction_exchange_refund.created_at)
                "
              />
            </b-col>

            <b-col cols="6" class="mb-2">
              <div class="grid-participations">
                <span class="subtitle-item">
                  {{ $t('shared.text_2094') }}
                </span>
              </div>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end mb-2">
              <div class="align-items-center d-flex">
                <span class="desctitle-item pr-1 dados-campo">
                  <template v-if="sale.local_currency">
                    {{ sale.local_currency.currency_symbol }}
                  </template>
                  {{ formatMoney(sale.local_amount) }}
                </span>
              </div>
              <div class="align-items-center d-flex pr-1">
                <span>
                  <img src="@/assets/img/icons/arrow-right.svg" />
                </span>
              </div>
              <div class="align-items-center d-flex">
                <span class="desctitle-item pr-1 dados-campo">
                  <template v-if="sale.target_currency_symbol">
                    {{ sale.target_currency_symbol }}
                  </template>
                  {{ formatMoney(sale.sale_transaction_exchange_refund.gross_amount * -1) }}
                </span>
              </div>
              <div class="align-items-center d-flex">
                <span id="info-value-refunded">
                  <img src="@/assets/img/icons/info.svg" />
                </span>
              </div>
              <b-tooltip
                class="info-left"
                target="info-value-refunded"
                :title="
                  $t('shared.text_2145') +
                  sale.local_currency.currency +
                  $t('shared.text_2146') +
                  sale.base_currency.currency +
                  '.'
                "
              />
            </b-col>
          </template>

          <template v-if="sale.base_currency.currency === 'BRL'">
            <b-col cols="12" class="mb-2">
              <div class="grid-participations">
                <span class="subtitle-item" v-if="sale.status === 'refunded'">{{
                  $t('shared.text_2176')
                }}</span>
                <span class="subtitle-item" v-else>{{
                  $t('shared.text_2181')
                }}</span>
                <span
                  class="desctitle-item item-refunded"
                  style="color: rgb(255, 12, 55); float: right"
                >
                  <template v-if="sale.base_currency">
                    - {{ sale.base_currency.currency_symbol }}
                  </template>
                  <span class="item-refunded item-part" v-if="sale.balance_refunded">
                    {{ formatMoney(sale.balance_refunded * -1) }}
                  </span>
                  <span class="item-refunded item-part" v-else> 0,00</span>
                </span>
              </div>
            </b-col>
            <b-col v-if="sale.balance_fee && sale.balance_fee != 0" cols="12" class="mb-2">
              <div class="grid-participations">
                <span class="subtitle-item">{{ $t('shared.text_2106') }}</span>
                <span
                  class="desctitle-item item-refunded"
                  style="color: rgb(255, 12, 55); float: right"
                >
                  <template v-if="sale.target_currency_symbol">
                    - {{ sale.target_currency_symbol }}
                  </template>
                  <span class="item-refunded item-part" v-if="sale.balance_fee">{{
                    formatMoney(sale.balance_fee)
                  }}</span>
                  <span class="item-refunded item-part" v-else> 0,00</span>
                </span>
              </div>
            </b-col>
          </template>

          <template v-if="sale.base_currency.currency !== 'BRL'">
            <template v-for="distribuition in distribuitions_refunded">
              <b-col cols="8" class="mb-2">
                <div class="grid-participations">
                  <span class="subtitle-item">
                    {{
                      distribuition.type
                        ? getParticipations(distribuition.type)
                        : getParticipations(distribuition.role)
                    }}
                    {{ distribuition.type ? '' : `"${distribuition.name}"` }}
                  </span>
                </div>
              </b-col>
              <b-col cols="4" class="mb-2">
                <div
                  class="align-items-center d-flex grid-participations h-100 justify-content-end"
                >
                  <span v-if="distribuition.participation > 0">
                    {{ distribuition.participation }}%
                  </span>

                  <span
                    v-if="sale.target_currency_symbol"
                    class="desctitle-item item-part"
                    style="color: rgb(255, 12, 55)"
                    >- {{ sale.target_currency_symbol }}
                    {{
                      distribuition.value
                        ? formatMoney(distribuition.value)
                        : formatMoney(distribuition.balance * -1)
                    }}
                  </span>
                </div>
              </b-col>
            </template>
          </template>

          <b-col cols="8">
            <div class="grid-participations">
              <span class="subtitle-item">{{ $t('shared.text_2438') }}</span>
            </div>
          </b-col>
          <b-col cols="4">
            <div
              class="align-items-center d-flex grid-participations h-100 justify-content-end"
            >
              <span
                class="desctitle-item item-refunded"
                :style="setColorsPositiveNegative(sale.balance)"
                style="float: right"
              >
                  <template v-if="sale.target_currency_symbol">
                  {{sale.balance < 0 ? '-':''}} {{ sale.target_currency_symbol }}
                </template>
                {{ sale.balance < 0 ? formatMoney(sale.balance * -1) : formatMoney(sale.balance) }}
              </span>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- Shipping Sale -->
    <b-row class="item-dados" v-if="sale.shipping_amount">
      <b-col>
        <h5 class="titulo-item">{{ $t('shared.text_2085') }}</h5>

        <div class="frete-item" v-if="sale.shipping_selected">
          <div class="infos-frete">
            <img :src="sale.shipping_selected.company.picture" />
            <div class="info-envio">
              <span>
                {{
                  sale.shipping_selected && sale.shipping_selected.name
                    ? sale.shipping_selected.name
                    : '--'
                }}
              </span>
              <span v-if="sale.shipping_selected.delivery_range">
                {{ sale.shipping_selected.delivery_range.min }} à
                {{ sale.shipping_selected.delivery_range.max }}
                {{ $t('shared.text_2086') }}
              </span>
              <span class="frete-valor">
                <template v-if="sale.base_currency">
                  {{ sale.base_currency.currency_symbol }}
                </template>
                {{ formatMoney(+sale.shipping_selected.price) }}
              </span>
            </div>
          </div>
        </div>
        <div class="frete-item" v-else>
          {{ $t('shared.text_2087') }}
        </div>
      </b-col>
    </b-row>

    <!-- Boleto -->
    <b-row
      v-if="sale.method == 'BOLETO' && !sale.affiliate && openType != 'lead'"
      class="item-dados"
    >
      <b-col>
        <h5 class="titulo-item">{{ $t('shared.text_2095') }}</h5>
        <p class="label-campo" v-if="sale.boleto_url || sale.order.boleto_url">
          Link
        </p>
        <p class="dados-campo">
          <a
            :href="sale.boleto_url || sale.order.boleto_url"
            target="_blank"
            class="dados-campo"
            >{{ sale.boleto_url || sale.order.boleto_url }}</a
          >
        </p>

        <p class="label-campo mt-2" v-if="isSeller">
          {{ $t('shared.text_2096') }}
        </p>
        <p class="dados-campo">
          {{
            sale.boleto_barcode ? sale.boleto_barcode : (sale.order ? sale.order.boleto_barcode : '')
          }}

          <font-awesome-icon
            icon="copy"
            style="cursor: pointer; margin-left: 5px"
            @click="copy"
          />
        </p>
      </b-col>
    </b-row>

    <!-- Metas -->
    <b-row class="item-dados" v-if="openType !== 'lead'">
      <b-col>
        <h5 class="titulo-item" ref="refundAncor">{{ $t('shared.text_2102') }}</h5>
        <div v-if="sale && sale.metas && sale.metas.length">
          <div
            v-for="meta in sale.metas"
            :key="meta.meta_key"
            class="meta-item"
          >
            <p class="key-meta">• {{ meta.meta_key }}</p>
            <p class="key-value">{{ meta.meta_value }}</p>
          </div>
        </div>
        <div v-else>
          <p class="sem-historico">
            {{ $t('shared.text_2103') }}
          </p>
        </div>
      </b-col>
    </b-row>

    <!-- Options -->
    <b-row v-if="!$store.getters.setPanel || $store.getters.getVerifyPermissions.Sales">
      <b-col>
        <div class="btn-flex" v-if="openType != 'lead'">
          <BaseButton
            v-if="showSaleRefundButton"
            variant="accent_outline"
            class="w-100"
            @click="showRefundModal = true"
            :disabled="disabledRefundButton"            
          >
            {{
              sale.status == 'refund_pending'
                ? $t('shared.text_2097')
                : $t('shared.text_2098')
            }}
          </BaseButton>
          <span class="refund-disclaimer" v-if="maxRefundPeriodExceeded">
            {{$t('shared.sale_details_refund_max_period', { maxRefundPeriod: maxRefundPeriod })}}
          </span>
          <br/>
          <Affiliation
            v-if="
              sale.seller_id == userID &&
              !sale.affiliate &&
              sale.status == 'paid'
            "
            :product_id="sale.product.id"
            from="sale"
          >
            <template v-slot:button="{ openModal }">
              <BaseButton
                variant="secondary_outline"
                class="w-100"
                @click="openModal(sale_id)"
              >
                {{ $t('shared.text_2099') }}
              </BaseButton>
            </template>
          </Affiliation>
          <Refund
            v-if="showRefundModal"
            :sale_id="String(sale_id)"
            :sale_method="sale.method"
            @close="onCloseRefundModal"
          />
          <BaseConfirmDialog
            v-if="
              sale.method == 'BOLETO' &&
              sale.status == 'waiting_payment' &&
              !sale.affiliate
            "
            @confirmed="updateSale"
            class="ml-2 d-inline-block"
          >
            <template v-slot:button="{ openModal }">
              <BaseButton
                variant="outline-info"
                class="w-100"
                @click="openModal"
                >{{ $t('shared.text_2100') }}</BaseButton
              >
            </template>
          </BaseConfirmDialog>

          <!-- <BaseButton variant="black" class="w-100" disabled>
            Refazer Entregas
          </BaseButton> -->
        </div>
        <div
          v-if="
            openType === 'lead' && sale.cellphone && (!$store.getters.setPanel || $store.getters.getVerifyPermissions.Sales)
          "
          class="btn-flex"
        >
          <BaseButton
            class="mt-3 w-100"
            variant="primary"
            @click="openLink(formatTel(sale.cellphone))"
          >
            {{ $t('shared.text_2101') }}
          </BaseButton>
        </div>
      </b-col>
    </b-row>

    <b-row
      class="item-dados"
      v-if="
        sale.country_code &&
        sale.country_code !== 'BR' &&
        sale.status === 'refunded'
      "
    >
      <b-col cols="12" class="mb-3">
        <p class="dados-campo">
          {{ $t('shared.text_2148') }}
        </p>
      </b-col>
      <b-col cols="12" class="mb-3">
        <p class="dados-campo">
          {{ $t('shared.text_2149') }}
        </p>
      </b-col>
    </b-row>
    </b-overlay>
  </div>
</template>
<script>
import { iconMethod, symbolCurrency, textMethod } from '@/helpers.js';
import { EventBus } from '@/main.js';
import Money from '@/mixins/money';
import Utils from '@/mixins/utils';
import ClientUpdateService from '@/services/resources/ClientUpdateService';
import GlobalSettingsService from "@/services/resources/GlobalSettings";
import SaleService from '@/services/resources/SaleService';
import UserSettingsService from "@/services/resources/UserSettingsService";
import { DateUnit, DateUtil } from '@/utils/DateUtil.js';
import errorPayment from '@/utils/errorPayment.js';
import moment from 'moment-timezone';
import Affiliation from './modals/Affiliation';
import Refund from './modals/RefundSolicitationMotives';

const serviceSale = SaleService.build()
const serviceClient = ClientUpdateService.build()
const serviceUserSettings = UserSettingsService.build();
const globalSettingsService = GlobalSettingsService.build();

export default {
  props: {
    distribuitions: {
      type: Array,
      default: () => []
    },
    distribuitions_refunded: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: () => true
    },
    id_contract: {
      type: String,
      default: () => ''
    },
    sale_id: {
      default: () => -1
    },
    sale: {
      type: Object,
      default: () => {}
    },
    openType: {
      type: String,
      default: () => null
    },
    statement_id: {
      type: Number,
      default: () => null
    },
    statement_created_at: {
      type: String,
      default: () => null
    },
    statement_type: {
      type: String,
      default: () => null
    },
    statement_available_date: {
      type: String,
      default: () => null
    },
    statement_withdraw_id: {
      type: String,
      default: () => null
    },
    sale_balance: {
      type: [String, Number],
      default: () => null
    },
    sale_balance_fee: {
      type: Number,
      default: () => 0
    },
    show_edit_email: {
      type: Boolean,
      default: () => false
    }
  },
  name: 'DetalhesVenda',
  components: {
    Refund,
    Affiliation
  },
  mixins: [Utils,Money],
  data() {
    return {
      collapse: false,
      interAccess: false,
      showRefundModal: false,
      isEditing: false,
      editedEmail: '',
      displayEmail: '',
      loadingEmailUpdate: false,
      allowEmailEdit: false,
      emailError: '',
      maxRefundPeriod: 90
    }
  },
  created() {
    let userInterIds = process.env.USER_INTER.substring(
      1,
      process.env.USER_INTER.length - 1
    ).split(',')
    this.interAccess = userInterIds.some(
      userId => parseInt(userId) === this.$store.getters.getProfile.id
    )
  },
  computed: {
    refundRequested() {
      if (this.sale.refund_solicitations != null) {
        for (var i = 0; i < this.sale.refund_solicitations.length; i++)
          if (
            this.sale.refund_solicitations[i].status == 'REQUESTED' &&
            this.sale.status != 'refunded'
          )
            return true
      }

      return false
    },
    showSaleRefundButton() {
      const isNotAffiliateAndCancelled = !this.sale.affiliate && this.sale.status === 'canceled'
      const isPaidOrHasPendingRefund = ['paid', 'refund_pending'].includes(this.sale.status)	
      return (!this.$store.getters.setPanel || this.$store.getters.getVerifyPermissions.Sales) &&
        (isPaidOrHasPendingRefund || isNotAffiliateAndCancelled)
    },
    disabledRefundButton() {
      return this.sale.status === 'refund_pending' || this.maxRefundPeriodExceeded
    },
    maxRefundPeriodExceeded() {
      const diffInDays = DateUtil.diff(this.sale.paid_at, new Date(), DateUnit.DAY) 
      return this.maxRefundPeriod < diffInDays
    }
  },
  mounted() {
    this.fetchUserSettings();
    this.fetchGlobalSettings();
    this.scrollToRefundButton();
  },
  methods: {
    scrollToRefundButton() {
      const s = this.$route.query.s;
      if (s !== 'undefined') {
        setTimeout(() => {
          this.$nextTick(() => {
            const ancor = this.$refs.refundAncor;
            if (ancor) {
              ancor.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          });
        }, 1000);
      }
    },
    formatCurrentCharge(charge) {
      if (charge != null) {
        return charge < 10 ? `0${charge}` : charge
      }
      return charge
    },
    getContractPeriod(period) {
      let r = ''
      switch (period) {
        case 90: {
          r = this.$t('shared.text_2165')
          break
        }
        case 180: {
          r = this.$t('shared.text_2166')
          break
        }
        case 365: {
          r = this.$t('shared.text_2167')
          break
        }
        default: {
          r = this.$t('shared.text_2164')
          break
        }
      }
      return r
    },
    filterCurrency(id) {
      if (id) {
        return symbolCurrency(id)
      }
      return 'R$'
    },
    checkError(error) {
      return errorPayment(error)
    },
    getParticipations(status) {
      let r = ''
      switch (status) {
        case 'AFFILIATE_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'ANTECIPATION_FEE': {
          r = this.$t('shared.text_2105')
          break
        }
        case 'SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'CREDIT_CARD_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'BOLETO_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'PIX_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'PAYPAL_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'HIGH_TICKET_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'HIGH_TICKET_BOLETO_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'HIGH_TICKET_PIX_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'HIGH_TICKET_PAYPAL_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'CREDIT_CARD_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'BOLETO_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'PIX_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'PAYPAL_SELLER_FEE': {
          r = this.$t('shared.text_2106')
          break
        }
        case 'MARKETPLACE_FEE': {
          r = this.$t('shared.text_2108')
          break
        }
        case 'USER_ANTECIPATION_FEE': {
          r = this.$t('shared.text_2109')
          break
        }
        case 'SELLER_FEE_INTERNATIONAL': {
          r = this.$t('shared.text_2110')
          break
        }
        case 'HIGH_TICKET_INTERNATIONAL_CREDIT_CARD_SELLER_FEE': {
          r = this.$t('shared.text_2110')
          break
        }
        case 'SELLER_INTEREST_FEE': {
          r = this.$t('shared.text_2111')
          break
        }
        case 'SELLER': {
          r = this.$t('shared.text_2063')
          break
        }
        case 'CO_SELLER': {
          r = this.$t('shared.text_2112')
          break
        }
        case 'AFFILIATE': {
          r = this.$t('shared.text_2113')
          break
        }
        case 'CURRENCY_CONVERSION_FEE': {
          r = 'Taxa de conversão de Moedas'
          break
        }
        case 'COUNTRY_FEE': {
          r = 'Taxa do País'
          break
        }
        default: {
          r = status
          break
        }
      }
      return r
    },
    getMethod(method) {
      return textMethod(method)
    },
    openContract(id_contract) {
      this.$router.push('/contratos?c_id=' + id_contract)
    },
    openNote(id_note) {
      this.$router.push('/central-de-notas?s_id=' + id_note)
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf('+55') > -1) {
          var tell = tel.replace(/[() -]/g, '')
          if (tell[0] === '+') {
            tell = tell.substring(1)
          }
          return `https://api.whatsapp.com/send?phone=${tell}`
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ''
          )}`
        }
      }
    },
    getStatus(status) {
      let r = {}
      if (status) {
        switch (status.toLowerCase()) {
          case 'processing': {
            r = { text: this.$t('shared.text_2114'), class: 'icon-blue' }
            break
          }
          case 'authorized': {
            r = { text: this.$t('shared.text_2115'), class: 'icon-blue' }
            break
          }
          case 'pending_approbation': {
            r = { text: this.$t('shared.text_2116'), class: 'icon-blue' }
            break
          }
          case 'paid': {
            r = {
              text: this.$t('shared.text_2117'),
              class: 'icon-green'
            }
            break
          }
          case 'refunded': {
            r = { text: this.$t('shared.text_2118'), class: 'icon-red' }
            break
          }

          case 'waiting_payment': {
            r = { text: this.$t('shared.text_2119'), class: 'icon-warning' }
            break
          }
          case 'pending_refund':
          case 'refund_pending': {
            r = { text: this.$t('shared.text_2120'), class: 'icon-warning' }
            break
          }
          case 'refused': {
            r = { text: this.$t('shared.text_2121'), class: 'icon-red' }
            break
          }
          case 'chargedback': {
            r = { text: this.$t('shared.text_2122'), class: 'icon-warning' }
            break
          }
          case 'delayed': {
            r = { text: this.$t('shared.text_2123'), class: 'icon-warning' }
            break
          }
          default: {
            r = { text: status, class: 'icon-black' }
            break
          }
        }
        return r
      } else return { text: status, class: 'icon-black' }
    },
    copy() {
      const el = document.createElement('textarea')
      el.value = this.sale.boleto_barcode
        ? this.sale.boleto_barcode : (this.sale.order ? this.sale.order.boleto_barcode : '')
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$bvToast.toast(this.$t('shared.text_2124'), {
        variant: 'success',
        title: this.$t('shared.text_2048'),
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    getCoSellerPercentage() {
      if (this.sale.product.co_sellers) {
        for (let i = 0; i < this.sale.product.co_sellers.length; i++) {
          if (this.sale.product.co_sellers[i].user_id == this.userID) {
            return this.sale.product.co_sellers[i].percentage
          }
        }
      }

      return 0
    },
    getCoSellerAmount() {
      if (this.sale.product.co_sellers) {
        for (let i = 0; i < this.sale.product.co_sellers.length; i++) {
          if (this.sale.product.co_sellers[i].user_id == this.userID) {
            const percentage = this.sale.product.co_sellers[i].percentage
            return ((this.sale.amount - this.sale.fee) * percentage) / 100
          }
        }
      }

      return 0
    },
    updateSale() {
      let data = {
        id: this.sale_id,
        status: 'pending_approbation'
      }

      serviceSale
        .update(data)
        .then(response => {
          if (response.success) {
            EventBus.$emit('relist-sales')
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    setColors(coin) {
      if (coin === 'BRL') return 'color: rgba(0, 132, 92, 1);'
      if (coin === 'USD') return 'color: rgba(255, 203, 55, 1);'
      if (coin === 'EUR') return 'color: rgb(40 30 176);'
      if (coin === 'GBP') return 'color: rgba(0, 0, 0, 1);'
      return 'color: #21a56e;'
    },
    setColorsPositiveNegative(value) {
      if (value >= 0) return 'color: #2133d2;'
      else return 'color: rgb(255, 12, 55)';
    },
    getIconMethod(method, currency_id) {
      return iconMethod(method, currency_id)
    },
    getDate(datetime) {
      return moment.tz(datetime, 'America/Sao_Paulo').format('DD/MM/YYYY HH:mm')
    },
    getHour(datetime) {
      return moment.tz(datetime, 'America/Sao_Paulo').format('HH:mm')
    },
    showPaidAt(status) {
      return (status == 'paid' || status == 'trialing' || status == 'refund_pending')
    },
    showRefundedAt(status) {
      return (status == 'refunded')
    },
    showChargedbackAt(status) {
      return (status == 'chargedback')
    },
    startEdit() {
      this.isEditing = true
    },
    cancelEdit() {
      this.editedEmail = this.displayEmail
      this.isEditing = false
    },
    saveEmail() {
      const isValid = this.validateEmail()

      if (isValid) {
        let data = {
          id: this.sale.client.id,
          email: this.editedEmail,
          saleId: this.sale.id
        }

        const name = this.sale.seller.name
        const email = this.displayEmail
        const order = this.sale.id
        const message = encodeURIComponent(this.$t('client.talk_to_support').replace('name', name).replace('email', email).replace('order', order))

        const fullLink = `https://wa.me/5511978213761?text=${message}`

        this.loadingEmailUpdate = true

        serviceClient
          .update(data)
          .then(response => {
            if (response.success) {
              this.isEditing = false
              this.displayEmail = response.email

              this.$bvToast.toast(
                this.$t('client.text_104'),
                {
                  title: this.$t('client.text_105'),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );

              EventBus.$emit('relist-sales')
            }

            if (response.error) {
              this.isEditing = false
              this.editedEmail = this.displayEmail

              this.$bvToast.toast(
                <b-link href={fullLink} target="_blank">{this.$t('client.click_to_talk_to_support')}</b-link>,
                {
                  title: this.$t('client.text_106'),
                  variant: "danger",
                  autoHideDelay: 10000,
                  appendToast: true,
                }
              );
            }
          })
          .catch(() => {
            this.$bvToast.toast(
              <b-link href={fullLink} target="_blank">{this.$t('client.click_to_talk_to_support')}</b-link>,
              {
                title: this.$t('client.text_106'),
                variant: "danger",
                autoHideDelay: 10000,
                appendToast: true,
              }
            );
          })
          .finally(() => {
            this.loadingEmailUpdate = false
          });
      }
    },
    fetchUserSettings() {
      let data = {
        id: this.userID || JSON.parse(localStorage.user).id,
      };

      serviceUserSettings
        .read(data)
        .then((response) => {
          response.forEach((item) => {
            if (item.key == "ENABLE_CLIENT_EMAIL_EDIT_BY_SELLER") {
              this.allowEmailEdit = item.value == "1";
            }
          });
        });
    },
    validateEmail() {
      const email = this.editedEmail;
      let errorMessage = '';

      // Expressão regular para validar email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      const commonDomains = [
          'gmail.com', 'hotmail.com', 'yahoo.com',
          'gmail.com.br', 'hotmail.com.br', 'icloud.com',
          'outlook.com', 'yahoo.com.br', 'uol.com.br',
          'live.com', 'terra.com.br', 'bol.com.br',
          'cogna.com.br', 'outlook.com.br', 'kroton.com.br',
          'parceiro-kroton.com.br', 'msn.com', 'usp.br',
          'escola.pr.gov.br', 'anhanguera.com', 'unesp.br',
          'stoodi.com.br', 'mepoupe.com', 'educacao.mg.gov.br',
          'voomp.com.br'
      ];

      if (!emailRegex.test(email)) {
          errorMessage = this.$t('client.invalid_email');
      } else {
          const domainPart = email.split('@')[1];
          if (!commonDomains.includes(domainPart)) {
              const similarDomain = commonDomains.some(domain => this.isSimilarDomain(domain, domainPart));
              if (similarDomain) {
                  errorMessage = this.$t('client.invalid_domain');
              }
          }
      }

      this.emailError = errorMessage;
      return errorMessage === '';
    },
    isSimilarDomain(domain1, domain2) {
        const levenshteinDistance = (a, b) => {
            const matrix = [];
            let i;
            for (i = 0; i <= b.length; i++) { matrix[i] = [i]; }
            let j;
            for (j = 0; j <= a.length; j++) { matrix[0][j] = j; }
            for (i = 1; i <= b.length; i++) {
                for (j = 1; j <= a.length; j++) {
                    if (b.charAt(i - 1) === a.charAt(j - 1)) {
                        matrix[i][j] = matrix[i - 1][j - 1];
                    } else {
                        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
                    }
                }
            }
            return matrix[b.length][a.length];
        };

        // Considera os domínios como semelhantes se a distância de Levenshtein for menor ou igual a 2
        return levenshteinDistance(domain1, domain2) <= 2;
    },
    onCloseRefundModal(success) {
      this.showRefundModal = false
      if (!success) return 
      EventBus.$emit('relist-sales')
      this.$bvToast.toast(this.$t('shared.text_1995'), {
        title: this.$t('shared.text_2421'),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
      this.$emit('reload')
    },
    async fetchGlobalSettings() {
      try {
        response = await globalSettingsService.search({ keys: 'REFUND_MAX_PERIOD'})
        this.maxRefundPeriod = Number(response[0].value)
      } catch (error) {
        this.maxRefundPeriod = 90
      }
    }
  },
  watch: {
    sale: {
      handler(newValue) {
        if (newValue.client && newValue.client.email) {
          this.isEditing = false
          this.editedEmail = newValue.client.email
          this.displayEmail = newValue.client.email
        }
      },
      immediate: true // Ensures the watcher runs on initial prop value as well
    }
  }
}
</script>

<style>
.custom-toast-margin{
  margin-right: 450px !important;
}
</style>

<style lang="scss" scoped>
.info-left {
  text-align: left !important;
}
.item-part {
  margin-left: 5px;
}
.panel {
  .sumary {
    font-size: 13px;
  }

  .product-name {
    font-size: 16px;
    font-weight: 600;
    display: block;
  }
  .product-name span {
    display: block;
    margin: 10px 0 !important;
  }

  .product-image {
    height: 170px;
    overflow: hidden;
    border-radius: 5px;
    background: #f5f2fc;
    display: flex;
    align-items: center;
  }
  .thumb-fake {
    width: 100%;
    color: silver;
    font-size: 32px;
    letter-spacing: 2px;
    justify-content: center;
  }

  .product-description {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: #81858e;
    font-weight: normal;
  }
}
.center {
  text-align: center;
}
.detalhe-item {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.detalhe-item + .detalhe-item {
  margin-top: 25px;
}
.detalhe-item:first-child img {
  margin-right: 23px;
}
.detalhe-item:nth-child(2) img {
  margin-right: 28px;
}
.detalhe-item:nth-child(3) img {
  margin-right: 20px;
}
header.b-sidebar-header {
  padding: 30px !important;
  padding-bottom: 0 !important;
}
.dados-campo {
  font-weight: normal;
  font-size: 13px;
  margin-top: 3px;
}
.item-dados {
  // border: 1px solid ;
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $black;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.title-item {
  font-size: 11px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.subtitle-item {
  font-size: 14px;
  color: #81858e;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.item-refunded {
  color: rgb(255, 12, 55);
  float: right;
}
.desctitle-item {
  font-size: 14px;
  color: #333;
  display: block;
  font-weight: normal !important;
  margin-bottom: 3px;
}

.label-campo span {
  font-weight: 600;
}
.label-campo + .label-campo {
  margin-top: 5px;
}

.btn-flex {
  display: flex;
  flex-direction: column;
}
.btn-flex div {
  margin-bottom: 13px;
}
.btn-flex div button,
.btn-flex button {
  width: 70%;
  height: 55px;
}
.whats-cliente {
  font-size: 14px;
  color: #2a63ab;
}
.whats-cliente svg {
  margin-left: 5px;
}

// metas

.meta-item + .meta-item {
  margin-top: 10px;
  border-top: 1px solid #ededf0;
  padding: 10px 0;
}

.meta-item .key-meta {
  font-weight: 600;
  margin-bottom: 5px;
}
.meta-item .key-value {
  font-weight: 500;
  margin-left: 10px;
}
.sem-historico {
  margin-top: 20px;
  font-size: 14px;
  color: #81858e;
}

.decoration-none {
  text-decoration: none;
}

.btn-link-contract {
  display: flex;
  align-items: center;
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 20px !important;
  border-radius: 5px;
  height: 45px;
}

.btn-link-contract img {
  margin-right: 10px;
}
.product-avaliacao {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 7px;
  font-size: 14px;
}

.product-avaliacao + .product-avaliacao {
  margin-top: 25px;
}
.product-avaliacao.text-avaliacao {
  font-weight: normal;
  align-items: flex-start;
}

.product-main {
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  gap: 20px;
  align-items: center;
}
.img-product {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-product,
.img-product img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ededf0;
  object-fit: cover;
}
.name-product span {
  font-size: 11px;
  color: #81858e;
}
.name-product .hash {
  font-size: 12px;
  color: #81858e;
  font-weight: inherit;
}
.name-product p {
  font-weight: 600;
}
.nota-status {
  display: flex;
  align-items: center;
  gap: 5px;
}
.badge-primary,
.badge-secondary {
  padding: 5px 8px;
  font-weight: 600;
  background-color: rgba(255, 185, 55, 0.2);
  color: #ffb937;
  white-space: pre-line;
  line-height: 1.5;
  text-align: left;
  font-size: 12px;
}
.badge-secondary {
  background: #ededf0;
  color: #333;
  padding: 10px;

  font-size: 13px;
}

// frete

.frete-item img {
  max-width: 80px;
  margin-bottom: 15px;
}

.frete-item .infos-frete {
  display: block;
  font-size: 15px;
  margin-bottom: 7px;
}

.frete-item .info-envio,
.frete-item .info-envio span {
  display: block;
  margin-bottom: 7px;
}

.info-envio .frete-valor {
  font-weight: 600;
}

@media screen and (max-width: 769px) {
  .frete-item .infos-frete {
    width: 100%;
  }

  .frete-item .info-envio {
    grid-template-columns: 1fr;
  }

  .info-envio span {
    font-size: 13px;
  }

  .info-envio span + span {
    margin-top: 3px;
  }
}

// collapse
.titulo-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
}
.titulo-arrow.ativo {
  margin-bottom: 15px;
}
.titulo-arrow.ativo img {
  transition: 0.3s;
  transform: rotate(180deg);
}

.collapse.show {
  width: 100%;
}

.grid-participations {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.grid-participations + .grid-participations {
  margin-top: 7px;
}
.chip {
  width: 30px;
  height: 30px;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid transparent;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.email-edit-icon {
  margin-left: 8px; /* Ajuste o valor conforme necessário */
  cursor: pointer;
}

.email-input {
  height: 25px !important;
  width: 250px;
  padding: 5px 0 15px 0 !important;
  margin-top: 10px !important;
  background: transparent;
  border: none !important;
  border-bottom: 1px solid #81858e !important;
  border-radius: 0 !important;
}

.email-error-message {
  color: #d9534f;
  font-size: 0.85em;
  margin-top: 5px;
}
.refund-disclaimer {
  font-size: 12px;
  color: #81858e;
  margin-top: 5px;
  font-style: italic;
  text-align: center;
}
</style>

