// const paymentMethods = "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS";
const paymentMethodsString = "CREDIT_CARD,BOLETO,PIX,PAYPAL";
const paymentMethodsList = [
  { id: "CREDIT_CARD", name: "Cartão de crédito" },
  { id: "BOLETO", name: "Boleto" },
  { id: "PIX", name: "Pix" },
  { id: "PAYPAL", name: "PayPal" },
  // { id: "TWO_CREDIT_CARDS", name: "Dois Cartões" },
]
const paymentMethodsSelect = [
  { name: "Cartão de crédito", value: "CREDIT_CARD" },
  { name: "Boleto", value: "BOLETO" },
  { name: "Pix", value: "PIX" },
  { name: "PayPal", value: "PAYPAL" },
  // { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
]


export { paymentMethodsString, paymentMethodsList, paymentMethodsSelect }