export default function (code) {
  let r = ''
  switch (code) {
    case "0001": {
      r =
        "Este cupom que foi utilizado não é válido.";
      break;
    }
    case "BANK": {
      r =
        "Algo deu errado na compra, é necessário o cliente entrar em contato com o banco do cartão.";
      break;
    }
    case "BLACKLIST_PURCHASE": {
      r = `Após inúmeras tentativas de pagamento o banco bloqueou o cartão do cliente.`;
      break;
    }
    case "INVALID_CVV": {
      r =
        "Código de segurança do cartão inválido.";
      break;
    }
    case "INVALID_CLIENT_DATA": {
      r =
        "Dados do cliente constam incorretos.";
      break;
    }
    case "DUPLICATE_PURCHASE": {
      r = "Cliente já efetuou uma compra deste produto";
      break;
    }
    case "PRODUCT_OUT_OF_STOCK": {
      r =
        "Produto sem estoque.";
      break;
    }
    case "CREDIT_CARD_OPERATOR": {
      r = "Algo deu errado na compra, é necessário o cliente entrar em contato com a operadora do cartão.";
      break;
    }
    case "INVALID_DATA": {
      r =
        "Os dados do cartão foram inseridos incorretamente";
      break;
    }
    case "INVALID_CREDIT_CARD": {
      r =
        "O cartão usado está desabilidato, bloqueado ou está vencido.";
      break;
    }
    case "INSUFFICIENT_FUNDS": {
      r =
        "Não há limite suficiente no cartão.";
      break;
    }
    case "INVALID_PAYMENT_TYPE": {
      r =
        "Método utilizado para o pagamento inválido.";
      break;
    }
    case "INVALID_INSTALLMENTS": {
      r =
        "Número ou valor das parcelas não foi autorizado.";
      break;
    }
    case "CURRENCY_NOT_SUPPORTED": {
      r = "Moeda não suportada.";
      break;
    }
    case "SUSPECTED_FRAUD": {
      r =
        "Transação não permitida, Suspeita de Fraude.";
      break;
    }
    case "GENERIC": {
      r =
        "Ocorreu um erro durante o processamento da compra!";
      break;
    }
    // assinatura
    case '0404': {
      r = 'Nenhuma assinatura foi efetuada com este documento.'
      break;
    }
    case '1404': {
      r = 'Esse produto não é uma assinatura.'
      break;
    }
    case '1405': {
      r = 'Não há nenhuma assinatura vinculada a este documento.'
      break;
    }
    default: {
      r =
        `Ocorreu um erro durante o processamento da compra. - Código: ${code}`;
      break;
    }
  }
  return r
}