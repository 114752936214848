<template>
  <div>
    <b-modal
      :id="`refund-solicitation-motives-${id}`"
      size="md"
      body-class="position-static"
      :title="$t('shared.text_2413')"
      @hide="close()"
    >
      <b-overlay
        no-wrap
        :show="loading"
        variant="dark"
        opacity="0.4"
        rounded="sm"
      />
      <b-container>
        <b-row>
          <b-col>
            <b-form-group :label="$t('shared.text_1990')" label-for="category">
              <b-form-select
                id="category"
                v-model="form.category"
                :options="categories"
                name="form.category"
                v-validate="'required'"
              ></b-form-select>
              <b-form-invalid-feedback :state="!errors.has('form.category')">
                {{ $t('shared.text_1991') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group :label="$t('shared.text_1992')" label-for="motive">
              <b-form-textarea
                id="motive"
                :placeholder="$t('shared.text_2414')"
                v-model="form.motive"
                rows="3"
                max-rows="8"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-container v-if="show_slip_inputs">
            <b-row class="mt-4">
              <b-col>
                <FormSearchableSelect
                  :label="$t('seller.statements.modals.bank_account.text_2710')"
                  id="bank_code"
                  name="form.bank_code"
                  v-model="form.bank_code"
                  :error="submit ? !errors.has('form.bank_code') : null"
                  :error_label="$t('shared.field_required')"
                  :options="filteredBanks"
                  @search="filterBanks"
                  v-validate="'required'"
                  placeholder="Digite o código ou nome do banco"
                />
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col class="mr-2">
                <FormInput
                  :label="$t('seller.statements.modals.bank_account.text_2713')"
                  id="agency_number"
                  name="form.agency_number"
                  v-model="form.agency_number"
                  :error="$t('shared.field_required')"
                >
                  <template #input="{ id, input_name: name, value, handleInput }">
                    <b-form-input
                      :id="id"
                      type="text"
                      :name="name"
                      :value="value"
                      @input="handleInput"
                      :state="submit ? !errors.has(name) : null"
                      v-validate="'required|numeric|max:20'"
                    >
                  </b-form-input>
                  </template>
                </FormInput>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col class="mr-2">
                <FormInput
                  :label="$t('seller.statements.modals.bank_account.text_2714')"
                  id="account_number"
                  name="form.account_number"
                  v-model="form.account_number"
                  :error="$t('shared.field_required')"
                >
                  <template #input="{ id, input_name: name, value, handleInput }">
                    <b-form-input
                      :id="id"
                      type="text"
                      :name="name"
                      :value="value"
                      @input="handleInput"
                      :state="submit ? !errors.has(name) : null"
                      v-validate="'required|numeric|max:20'"
                    >
                  </b-form-input>
                  </template>
                </FormInput>
              </b-col>
              <b-col>
                <FormInput
                  :label="$t('seller.statements.modals.bank_account.text_802')"
                  id="account_dv"
                  name="form.account_dv"
                  v-model="form.account_dv"
                  :error="$t('shared.field_required')"
                >
                  <template #input="{ id, input_name: name, value, handleInput }">
                    <b-form-input
                      :id="id"
                      type="text"
                      :name="name"
                      :value="value"
                      @input="handleInput"
                      :state="submit ? !errors.has(name) : null"
                      v-validate="'required|numeric|max:2'"
                    >
                  </b-form-input>
                  </template>
                </FormInput>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col class="mr-2">
                <FormInput
                  :label="$t('seller.statements.modals.bank_account.account_owner')"
                  id="account_owner"
                  name="form.account_owner"
                  v-model="form.account_owner"
                  :error="$t('shared.field_required')"
                >
                  <template #input="{ id, input_name: name, value, handleInput }">
                    <b-form-input
                      :id="id"
                      type="text"
                      :name="name"
                      :value="value"
                      @input="handleInput"
                      :state="submit ? !errors.has(name) : null"
                      v-validate="'required|max:255'"
                    >
                  </b-form-input>
                  </template>
                </FormInput>
              </b-col>
              <b-col>
                <FormInput
                  :label="$t('shared.text_2077')"
                  id="document_number"
                  name="form.document_number"
                  v-model="form.document_number"
                  :error="$t('shared.field_required')"
                >
                  <template #input="{ id, input_name: name, value, handleInput }">
                    <b-form-input
                      :id="id"
                      type="text"
                      :name="name"
                      :value="value"
                      @input="handleInput"
                      :state="submit ? !errors.has(name) : null"
                      v-mask="document_mask"
                      v-validate="'required|cpf_cnpj|max:18'"
                      placeholder="Ex: ###.###.###-##"
                    >
                  </b-form-input>
                  </template>
                </FormInput>
              </b-col>
            </b-row>
          </b-container>
        </b-row>
      </b-container>
        <template v-slot:modal-footer="{ cancel }">
          <BaseButton variant="link-info" @click="close(cancel)" class="mr-4">
            {{ $t('shared.text_1993') }}
          </BaseButton>
          <BaseButton variant="primary" @click="onSubmit()">
            {{ $t('shared.text_1994') }}
          </BaseButton>
        </template>
    </b-modal>
  </div>
</template>
<script>
import RefundService from "@/services/resources/RefundService";
import BankService from "@/services/resources/BankService";

const bankService = BankService.build();

const serviceRefund = RefundService.build();

export default {
  props: {
    sale_id: {
      type: String,
      required: true,
      default: -1
    },
    sale_method: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      id: parseInt(Math.random() * 1000),
      banks: [],
      filteredBanks: [],
      loading: false,
      form: {
        motive: null,
        category: null,
        bank_code: null,
        agency_number: null,
        agency_dv: null,
        account_number: null,
        account_dv: null,
        document_number: null,
        account_owner: null,
      },
      submit: false,
      categories: [
        { value: null, text: this.$t('shared.text_2415') },
        { value: "BUY_WRONG", text: this.$t('shared.text_2416') },
        { value: "GAVE_UP_BUYING", text: this.$t('shared.text_2417') },
        { value: "EXPECTATIONS_NOT_MEETED", text: this.$t('shared.text_2418') },
        { value: "PRODUCT_NOT_DELIVERED", text: this.$t('shared.text_2419') },
        { value: "DUPLICATE_PURCHASE", text: this.$t('shared.text_2420') },
      ]
    }
  },
  mounted() {
    this.$bvModal.show(`refund-solicitation-motives-${this.id}`)
    if (this.sale_method === 'BOLETO') this.searchBanks();
  },
  components: {
    FormInput: () => import("@/components/shared/forms/FormInputGeneric.vue"),
    FormSearchableSelect: () => import("@/components/shared/forms/FormSearchableSelect.vue"),
  },
  computed: {
    show_slip_inputs() {
      return this.sale_method === 'BOLETO'
    },
    document_mask() {
      const cpf_length = 11;
      const cpf_without_mask_length = (this.form.document_number || '').replace(/\D/g, "").length;
      if (cpf_without_mask_length <= cpf_length) {
        return "###.###.###-##";
      }

      return "##.###.###/####-##";
    },
  },
  methods: {
    /**
     * @description Close modal and emit close event with success case refund was created
     * @param {*} bModalcancel  Param to invoke the cancel function of b-modal
     * @param {*} success 
     */
    close(bModalcancel, success = false) {
      if (bModalcancel) bModalcancel()
      this.$emit("close", success);
    },
    async onSubmit() {
      this.submit = true
      this.loading = true
      try {
        const result = await this.$validator.validateAll()
        if (!result) return

        const data = {
          sale_id: this.sale_id,
          category: this.form.category,
          motive: this.form.category,
          bank_code: this.form.bank_code && this.form.bank_code.value,
          agency_number: this.form.agency_number,
          agency_dv: this.form.agency_dv,
          account_number: this.form.account_number,
          account_dv: this.form.account_dv,
          document_number: this.form.document_number,
          account_owner: this.form.account_owner
        };

        await serviceRefund.create(data)

        this.close(null, true)
      } finally {
        this.loading = false
      }
    },
    filterBanks(text) {
      this.filteredBanks = this.banks.reduce((acc, element) => {
        const codeIncludes = String(element.code.toLocaleLowerCase()).includes(text.toLocaleLowerCase())
        const nameIncludes = element.name
          .toLocaleLowerCase()
          .includes(text.toLocaleLowerCase())
        
        if (codeIncludes || nameIncludes) {
          acc.push({
            value: element.code,
            text: element.name
          });
        }
        return acc;
      }, []);
    },
    async searchBanks() {
      try {
        const response = await bankService.search()
        this.banks = response
        this.filteredBanks = response.map((bank) => ({
            value: bank.code,
            text: bank.name
          }));
      } catch (error) {
        this.$bvToast.toast(
          this.$t('client.text_103'),
          {
            title: this.$t('views.seller.statements.refund'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
      }
    },
  },
  beforeDestroy() {
    this.$bvModal.hide(`refund-solicitation-motives-${this.id}`);
  }
};
</script>

<style lang="scss" scoped>
.Affiliate-item {
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    background: #eee;
    color: #111;
  }
}
.active {
  background: $base-color;
  color: #fff;
}
.close-btn {
  background-color: transparent;
  color: darkgray;
  border: none;
  font-size: 20px;
  font-weight: 300;
}
</style>
