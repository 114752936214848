<template>
  <BaseModal
    name="leads-filters"
    size="lg"
    :title="$t('seller.leads.text_2484')"
    @shown="openModal"
  >
    <b-row>
      <b-col cols="12">
        <div class="top-filter">
          <div>
            <p>{{ $t('seller.leads.text_279') }}</p>
          </div>
          <BaseButton
            variant="info3"
            class="link-documentacao"
            id="limpar"
            @click="limparLocal"
            >{{ $t('seller.leads.text_2486') }}</BaseButton
          >
        </div>
        <b-form novalidate>
          <b-row>
            <b-col>
              <b-form-group :label="$t('seller.leads.text_2484')">
                <multiselect
                  v-model="filter.products"
                  id="ajax"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.leads.text_2488')"
                  :selectLabel="$t('seller.leads.text_2489')"
                  :deselectLabel="$t('seller.leads.text_2490')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="aux_product_debounce"
                >
                  <span slot="noOptions">{{
                    $t('seller.leads.text_2488')
                  }}</span>

                  <span slot="noResult">{{ $t('seller.leads.text_280') }}</span>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        {{ $t('seller.leads.text_2493') }}
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit">
        {{ $t('seller.leads.text_2485') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from 'vue-multiselect'
import _ from 'lodash'

import ProductService from '@/services/resources/ProductService'
const serviceProduct = ProductService.build()

import ContractService from '@/services/resources/ContractService'
const serviceContract = ContractService.build()

import SaleService from '@/services/resources/SaleService'
const serviceSale = SaleService.build()

import LeadService from '@/services/resources/LeadService'
import { paymentMethodsList } from '../../../../config/paymentMethods'
const serviceLead = LeadService.build()

export default {
  props: {
    value: {
      type: Number
    }
    // pre_filter: {
    //   type: Object,
    //   default() {
    //     return {};
    //   },
    // },
  },
  components: {
    Multiselect
  },
  data() {
    return {
      pre_filter: {},
      contemFilterLocal: false,
      search: '',
      loading: false,
      loading_contract: false,
      affiliates: [],

      filter: {
        affiliates: [],

        status: [],
        method: [],
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: []
      },
      products: [],
      contracts: [],
      method_list: []
    }
  },

  methods: {
    selecionarTodos(type) {
      switch (type) {
        case 'metodos': {
          this.filter.method = []
          break
        }
        case 'status': {
          this.filter.status = [
            {
              id: 'processing',
              name: 'Processando'
            },
            {
              id: 'authorized',
              name: 'Transação autorizada'
            },
            {
              id: 'paid',
              name: 'Pago'
            },

            {
              id: 'refunded',
              name: 'Reembolsado'
            },
            {
              id: 'waiting_payment',
              name: 'Aguardando pagamento'
            },

            {
              id: 'refund_pending',
              name: 'Reembolso pendente'
            },
            {
              id: 'refused',
              name: 'Recusado'
            },

            {
              id: 'chargedback',
              name: 'ChargeBack'
            },
            {
              id: 'delayed',
              name: 'Pago'
            }
          ]
          break
        }
      }
    },
    // produto
    aux_product_debounce(query) {
      this.loading = true
      this.search = query
      this.debounce_product()
    },
    debounce_product: _.debounce(function () {
      this.fetchProducts(this.search)
    }, 500),

    // contrato
    aux_contract_debounce(query) {
      this.loading_contract = true
      this.search = query
      this.debounce_contract()
    },
    debounce_contract: _.debounce(function () {
      this.fetchContract(this.search)
    }, 500),

    onSubmit() {
      let data = {}
      if (this.filter.products.length) {
        data.products = this.filter.products.map(item => item.id)
      }

      // if (this.filter.contracts.length) {
      //   data.contracts = this.filter.contracts.map((item) => item.id);
      // }

      this.$emit('filter', data)
      this.$bvModal.hide('leads-filters')
    },
    fetchProducts(search = null) {
      let data = {
        list: true
      }

      if (search) data.search = search

      this.products = []

      serviceProduct
        .search(data)
        .then(response => {
          response.forEach(element => {
            this.products.push({ id: element.id, name: element.name })
          })
        })
        .catch(err => {
          console.log(err)
          this.products = []
        })
        .finally(() => {
          this.loading = false
        })
    },

    fetchContract(search = null) {
      let data = {
        // list: true,
        id: search
      }

      // if (search) data.search = search;

      this.contracts = []

      serviceContract
        // .search(data)
        .read(data)
        .then(response => {
          if (response.data) {
            response.data.data.forEach(element => {
              this.contracts.push({
                id: element.id,
                name: `${element.contract.live.product.name} | ${element.client.name} `
              })
            })
          } else {
            this.contracts.push({
              id: response.id,
              name: `${response.contract.live.product.name} | ${response.client.name} `
            })
          }
        })
        .catch(err => {
          this.contracts = []
          console.log(err)
        })
        .finally(() => {
          this.loading_contract = false
        })
    },
    fetchAffiliates() {
      let data = {
        id: '/affiliate'
      }

      this.affiliates = []

      serviceSale
        .read(data)
        .then(response => {
          response.forEach(element => {
            this.affiliates.push({
              id: element.id,
              name: `${element.name}  - ${element.email}`
            })
          })
        })
        .catch(err => {
          console.log(err)
          this.affiliates = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    limparLocal() {
      window.localStorage.removeItem('sales_filters_aux')
      window.localStorage.removeItem('sales_filters')
      this.filter = {
        status: [
          {
            id: 'paid',
            name: 'Pago'
          },
          {
            id: 'waiting_payment',
            name: 'Aguardando pagamento'
          }
        ],
        method: paymentMethodsList,
        meta_key: null,
        meta_value: null,
        products: [],
        contracts: [],
        affiliates: []
      }
      this.products = []
      this.contracts = []
      this.affiliates = []
      this.verificaLocal()
    },
    verificaLocal() {
      if (window.localStorage.sales_filters_aux) {
        this.contemFilterLocal = true
      } else {
        this.contemFilterLocal = false
      }
    },

    openModal() {
      this.verificaLocal()
      if (window.localStorage.sales_filters_aux) {
        this.pre_filter = JSON.parse(window.localStorage.sales_filters_aux)
        this.filter = this.pre_filter
      }
    }
  },
  mounted() {
    // this.fetchAffiliates();
  },
  created() {
    // this.fetchProducts();
  }
}
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-filter {
    display: block;
  }
  .top-filter #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
