<template>
  <div v-if="show" :id="uid" class="container">
    <div :class="`select-box-${uid}`" class="select-box">
      <div class="options-container" :class="`options-container-${uid}`">
        <div
          style="height: 40px; cursor: pointer;"
          :key="`${option.value}${uid}`"
          @click="onInput(option.value)"
          v-for="option in options"
          class="option"
          :class="`option-${uid}`"
        >
          <div
            v-if="!notcolor"
            :class="`status status--big status--${option.value}`"
          ></div>
          <input :value="option.value" type="radio" class="radio" :name="uid" />
          <label :for="option.value"> {{ option.text }} </label>
        </div>
      </div>

      <div
        :id="`selected-${uid}`"
        @click="toggleActive"
        :class="`selected-${uid}`"
        class="selected"
      >
        <div
          :id="`status-icon-${uid}`"
          class="status"
          :class="`${statusClass}`"
        ></div>
        {{ getTextByStatus(getValue) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      statusClass: "",
    };
  },
  props: {
    uid: {
      type: String,
    },
    text: {
      type: String,
      default: "Selecione um item",
    },
    value: {
      type: String,
    },
    notcolor: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true, // [ { id: Number, name: String } ]
    },
  },
  computed: {
    getValue() {
      return this.value;
    },
  },
  methods: {
    getTextByStatus(status) {
      const text = this.options.filter((f) => f.value === status);
      const ok_text = text.length ? text[0].text : this.text;
      return ok_text;
    },
    toggleActive() {
      const optionsContainer = document.querySelector(
        `.options-container-${this.uid}`
      );
      optionsContainer.classList.toggle("active");
    },
    onInput(value) {
      this.$emit("change", value);
      this.getStatusClass(value);
    },
    getStatusClass(value) {
      this.show = false;
      this.statusClass = `status--${value}`;
      setTimeout(() => {
        this.show = true;
      }, 80);
    },
  },
  mounted() {
    const selected = document.getElementById(`selected-${this.uid}`);

    const optionsList = document.querySelectorAll(`.option-${this.uid}`);
    const optionsContainer = document.querySelector(
      `.options-container-${this.uid}`
    );

    optionsList.forEach((o) => {
      o.addEventListener("click", () => {
        selected.innerHTML = o.querySelector("label").innerHTML;
        optionsContainer.classList.remove("active");
      });
    });
    this.getStatusClass(this.value);
  },
};
</script>

<style scoped>
.container {
  margin-top: 100px;
  padding: 32px;
  width: 100%;
  z-index: 10;
}

.select-box {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.select-box .options-container {
  padding-top: 12px;
  padding-bottom: 12px;
  background: #fff;
  color: #f5f6fa;
  max-height: 0;
  width: 100%;
  opacity: 0;
  transition: all 0.4s;
  border-radius: 3px;
  overflow: auto;
  position: absolute;
  margin-top: 45px;
  z-index: 10;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);

  order: 1;
}

.selected {
  background: rgba(0, 0, 0, 0);
  border-radius: 8px;
  margin-bottom: 8px;
  color: #f5f6fa;
  position: relative;

  order: 0;
}

.selected::after {
  content: "";
  background: url("../../assets/img/icons/arrow-down-02.svg");
  background-size: contain;
  background-repeat: no-repeat;

  position: absolute;
  height: 100%;
  width: 27px;
  right: 10px;
  top: 10px;

  transition: all 0.4s;
}

.select-box .options-container.active {
  max-height: 400px;
  opacity: 1;
  overflow-y: scroll;
}

.select-box .options-container.active + .selected::after {
  transform: rotateX(180deg);
  top: -10px;
}

.select-box .selected {
  padding: 12px 0px;
  cursor: pointer;
  color: #333;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.select-box .option {
  padding: 0px 12px;
  display: flex;
  margin-left: 12px;
  align-items: center;
  z-index: 20;
}

.select-box .option:hover {
  background: #fbfbfa;
  border-radius: 6px;
}

.select-box label {
  cursor: pointer;
}

.select-box .option .radio {
  display: none;
}

.radio {
  height: 40px;
}

.status {
  width: 6px;
  height: 6px;
  background: #333;
  border-radius: 50%;
  margin-right: 6px;
  transition: all 0.3s ease;
}

.status--big {
  width: 12px;
  height: 12px;
  margin-bottom: 6px;
  margin-right: 24px;
}

.status--opened {
  background: #002363;
}
.status--objection {
  background: #ffcb37;
}
.status--in_negociation {
  background: #2ed7ee;
}
.status--lost_trading {
  background: #ff0c37;
}
.status--win {
  background: #002363;
}
</style>
